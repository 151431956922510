import React, { Component, Fragment, useEffect,useState } from 'react';
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useHistory } from "react-router-dom";
// window.jquery = window.$ = $
import './sidebar.css'
function Sidebar() {
let AdminPanelAccess=false;
let uploadViewAccess=false;
let databasehelperAccess=false;
let profitabilityDiagnosisAccess=false;
let reportsAccess=false;
const [showReturnDate,setShowReturnDate]=useState(false);
const [showSaleDate,setShowSaleDate]=useState(false);
const [showFull,setShowFull]=useState(false);

const [showSummary,setShowSummary]=useState(false);

const [showTiered,setShowTiered]=useState(false);

const [showGatewayDecline,setShowGatewayDecline]=useState(false);

const [showHeaterDE,setshowHeaterDE]=useState(false);
const [showHeaterUK,setshowHeaterUK]=useState(false);
const [showHeaterUS,setshowHeaterUS]=useState(false);

const [showMasterTable,setShowMasterTable]=useState(false);




const history = useHistory();
if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
    history.push('/');
    return false;
}
const userdata=JSON.parse(localStorage.getItem('userdata'));

const sidebaretype=userdata.type;

if(sidebaretype=='superadmin' || sidebaretype=='owneradmin'){
    AdminPanelAccess=true;
    reportsAccess=true;
    uploadViewAccess=true;
}
else if(sidebaretype=='databasehelper'){
    uploadViewAccess=true;
}
else if(sidebaretype=='subadmin'){
    reportsAccess=true;
    uploadViewAccess=true;
}
else if( sidebaretype=='user'){
    reportsAccess=true;
}else if( sidebaretype=='admin'){
    AdminPanelAccess=true;
}



function handleClick() {
    history.push("/home");
  }
  function handleShowHideAcc(type){
        if(type=='sale'){
            if(showSaleDate==true){
            setShowSaleDate(false);
            setShowReturnDate(false);
            }else if(showSaleDate==false){
            setShowSaleDate(true);
            setShowReturnDate(false);
            }
        }else if(type=='return'){
            if(showReturnDate==true){
                setShowReturnDate(false);
                setShowSaleDate(false);
                }else if(showReturnDate==false){
                setShowReturnDate(true);
                setShowSaleDate(false);
                }
        }else if(type=='full'){
            
            // setShowFull(true);
            if(showFull==true){
                setShowFull(false);
                setShowSummary(false);
                setShowTiered(false);
            }else if(showFull==false){
                setShowFull(true);
                setShowSummary(false);
                setShowTiered(false);
                
            }
            
        }else if(type=='summary'){

            if(showSummary==true){
                setShowFull(false);
                setShowSummary(false);
                setShowTiered(false);
            }else if(showSummary==false){
                setShowFull(false);
                setShowSummary(true);
                setShowTiered(false);
            }
        }
        else if(type=='tiered'){
            if(showTiered==true){
                setShowTiered(false);
                setShowFull(false);
                setShowSummary(false);
            }else if(showTiered==false){
                setShowTiered(true);
                setShowFull(false);
                setShowSummary(false);
            }
            
            
        }else if(type=='gateway'){
            if(showGatewayDecline==true){
                setShowGatewayDecline(false)
            }else if(showGatewayDecline==false){
                setShowGatewayDecline(true)
            }
            
        }else if(type=='HeaterDE'){
            if(showHeaterDE==true){
            setshowHeaterDE(false);
            setshowHeaterUK(false);
            setshowHeaterUS(false);
            }else if(showHeaterDE==false){
            setshowHeaterDE(true);
            setshowHeaterUK(false);
            setshowHeaterUS(false);
            }
            
        }else if(type=='HeaterUK'){
            if(showHeaterUK==true){
                setshowHeaterDE(false);
                setshowHeaterUK(false);
                setshowHeaterUS(false);
                
            }else if(showHeaterUK==false){
                setshowHeaterUK(true);
                setshowHeaterDE(false);
                setshowHeaterUS(false);
                }

        }else if(type=='HeaterUS'){
            if(showHeaterUS==true){
                setshowHeaterDE(false);
                setshowHeaterUK(false);
                setshowHeaterUS(false);
                
            }else if(showHeaterUS==false){
                setshowHeaterUS(true);
                setshowHeaterUK(false);
                setshowHeaterDE(false);
                }
           
        }else if(type=='MasterTable'){console.log('heye',showMasterTable)
        if(showMasterTable==true){
            setShowMasterTable(false)
            
            }else if(showReturnDate==false){
                setShowMasterTable(true)
            }
    }
  }
  




      
    return (
        <aside id="sidebar-wrapper">
            <div className="sidebar-brand">
            
                <div className='homelogoicon' onClick={handleClick}>
                <h2 >Clearer Data<span>ANALYTICS SIMPLIFIED</span></h2>
                </div>
            </div>
            <div className="sidebar-menu-accordian">
                <Accordion>
                

                    {/* {uploadViewAccess && 

                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Database Tables - Upload</Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-unstyled sidebar-child-menu">
                           
                              
                              
                             

                                <li className="list-item">
                                <Link to="/uploadAffiliate">AFFILIATES</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/uploadCustomer">CUSTOMERS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/uploadCardknox">CARDKNOX TRANSACTIONS</Link>
                                </li>
                                
                                <li className="list-item">
                                    <Link to="/uploadCustomCPA">CUSTOM CPA</Link>
                                </li>
                                
                                <li className="list-item">
                                    <Link to="/uploadEverflow">EVERFLOW CONVERSIONS</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/uploadOffer">OFFERS</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/uploadOrder">ORDERS</Link>
                                </li>
                                

                                <li className="list-item">
                                    <Link to="/uploadPaypal">PAYPAL</Link>
                                </li>
                                
                                <li className="list-item">
                                    <Link to="/uploadProduct">PRODUCTS</Link>
                                </li>
                               
                                
                                <li className="list-item">
                                    <Link to="/uploadShipments">SHIPMENTS</Link>
                                </li>

                                
                                
                                
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

} */}

{uploadViewAccess && 
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Database Tables</Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-unstyled sidebar-child-menu">
                              
                                <li className="list-item">
                                <Link to="/viewAffiliate">Affiliates - Everflow Shopware</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/viewWowCampaign">Campaigns - Wowsuite</Link>
                                </li>


                                <li className="list-item">
                                    <Link to="/viewEverflow">Conversions - Everflow Shopware</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/viewWowConversions">Conversions - Everflow Wowsuite</Link>
                                </li>
 
                                <li className="list-item">
                                    <Link to="/viewCustomCPA">Custom CPA - Everflow Shopware</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/viewCustomer">Customers - Shopware</Link>
                                </li>
                              
                                <li className="list-item">
                                    <Link to="/viewOffer">Offers - Everflow Shopware</Link>
                                </li>
                                
                                <li className="list-item">
                                    <Link to="/viewOrder">Orders - Shopware</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/viewWoworder">Orders - Wowsuite</Link>
                                </li>

                                                      
                                <li className="list-item">
                                    <Link to="/viewProduct">Products - Shopware</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/viewShipstationProducts">Products - Wowsuite Shipstation</Link>
                                </li>

                                

                                <li className="list-item">
                                    <Link to="/viewShipments">Shipments - Shipstation</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/viewCardknox">Transactions - Cardknox</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/viewBluesnap">Transactions - Bluesnap</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/viewBanquest">Transactions - Banquest</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/viewPaypal">Transactions - Paypal</Link>
                                </li>

                                <li className="list-item">
                                    <Link to="/viewStripe">Transactions - Stripe</Link>
                                </li>

                                {/* <Accordion.Item eventKey="11">
                        <Accordion.Header>Master Tables</Accordion.Header>
                        <Accordion.Body> */}
                            <h2 class="accordion-header">
                            <button type="button" aria-expanded="true" class="accordion-button" onClick={() =>handleShowHideAcc('MasterTable')}>Master Tables</button>
                            </h2>
                            {showMasterTable && (
                                
                            <ul className="list-unstyled sidebar-child-menu">
                               
                            <li className="list-item">
                                <Link to="/master/viewChargeback">Chargeback Transactions</Link>
                            </li>            
                            <li className="list-item">
                                    <Link to="/master/countries">Countries - Shopware</Link>
                           </li>
                           
                           <li className="list-item">
                                    <Link to="/master/saleschannel">Sales Channels - Shopware</Link>
                           </li>
                           <li className="list-item">
                                    <Link to="/master/tags">Tags - Shopware</Link>
                           </li>
                           <li className="list-item">
                                    <Link to="/master/manufacturer">Product Manufacturers - Shopware</Link>
                           </li>

                           

                           <li className="list-item">
                                    <Link to="/manageNotableOrder">Manage Notable Orders</Link>
                           </li>
                           
                                       
                                
                            </ul>
                             )}
                        {/* </Accordion.Body>
                    </Accordion.Item> */}
          
                                
                              
                                
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

}

{/* {uploadViewAccess && 
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>Database Tables - Export</Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-unstyled sidebar-child-menu">
                               
                                                
                            <li className="list-item">
                                    <Link to="/download/affiliate">AFFILIATES</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/customer">CUSTOMERS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/cardknox">CARDKNOX TRANSACTIONS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/customcpa">CUSTOM CPA</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/everflow">EVERFLOW CONVERSIONS</Link>
                                </li>
                                
                                
                                <li className="list-item">
                                    <Link to="/download/offer">OFFERS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/order">ORDERS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/product">PRODUCTS</Link>
                                </li>
                                <li className="list-item">
                                    <Link to="/download/paypal">PAYPAL</Link>
                                </li>
                               
                                <li className="list-item">
                                    <Link to="/download/shipments">SHIPMENTS</Link>
                                </li>
                                
                               

                                

                               
                                
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

} */}


{/* {uploadViewAccess && 
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>Master Tables</Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-unstyled sidebar-child-menu">
                               
                                                
                            <li className="list-item">
                                    <Link to="/master/countries">COUNTRIES - SHOPWARE</Link>
                           </li>
                           <li className="list-item">
                                    <Link to="/master/saleschannel">SALES CHANNELS - SHOPWARE</Link>
                           </li>
                           <li className="list-item">
                                    <Link to="/master/tags">TAGS - SHOPWARE</Link>
                           </li>
                           <li className="list-item">
                                    <Link to="/master/manufacturer">PRODUCT MANUFACTURERS - SHOPWARE</Link>
                           </li>
                                       
                                
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>

} */}

{reportsAccess && 
<Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Performance Reports</Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-unstyled sidebar-child-menu">
                                <li className="list-item">
                                    <Link to="/reports/salesperformance/consumption">Consumption Reports</Link>
                                </li>
                                {/* <li className="list-item">
                                    <Link to="/reports/salesperformance/consumption/wowsuite">Consumption Report (Wowsuite)</Link>
                                </li> */}

                                {/* <li className="list-item">
                                <Link to="/reports/profitsdiagnosis/profitview">Profits Report</Link>
                                </li> */}

                                <li className="list-item">
                                <Link to="/reports/profitsdiagnosis/layeredview">Profits Layered Report</Link>
                                </li>


                                
                                {/* <li className="list-item">
                                <Link to="/reports/profitsdiagnosis/profitview">Profits Report (ShopWare)</Link>
                                </li>
                                <li className="list-item">
                                <Link to="/reports/profitsdiagnosis/profitview/wowsuite">Profits Report (Wowsuite)</Link>
                                </li> */}
                                <li className="list-item">
                                <Link to="/reports/TakeRate">Take Rate Report (ShopWare)</Link>
                                </li>
                                {/* <li className="list-item">
                                <Link to="/reports/DeclineReasonReport/banquest">Declines Report (Banquest)</Link>
                                </li>
                                <li className="list-item">
                                <Link to="/reports/DeclineReasonReport/bluesnap">Declines Report (BlueSnap)</Link>
                                </li>
                                <li className="list-item">
                                <Link to="/reports/DeclineReasonReport/cardknox">Declines Report (Cardknox)</Link>
                                </li>
                                
                                
                                <li className="list-item">
                                <Link to="/reports/DeclineReasonReport/stripe">Declines Report (Stripe)</Link>
                                </li> */}

                                {/* <li className="list-item">
                                <Link to="/reports/DeclineReasonReportComplete">Declines Report</Link>
                                </li> */}

                                <li className="list-item">
                                <Link to="/reports/DeclineReasonReportLayered">Declines Report Layered</Link>
                                </li>
                                
                                
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>                 
}

{/* <Accordion.Item eventKey="11">
                        <Accordion.Header>Take Rate Reports</Accordion.Header>
                        <Accordion.Body>
                        
                            <ul className="list-unstyled sidebar-child-menu" > 

                            <li className="list-item">
                                <Link to="/reports/TakeRate">Take Rate</Link>
                            </li>
                
                             </ul>
                        </Accordion.Body>
                    </Accordion.Item> */}


                    {/* {profitabilityDiagnosisAccess && 
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Profitability Diagnosis</Accordion.Header>

                        <Accordion.Body>
                        <ul className="list-unstyled sidebar-child-menu" >
                        <li className="list-item">
                                    <Link to="/reports/profitsdiagnosis/profitview">Profit View</Link>
                            </li>
                            </ul> 
                           
                        </Accordion.Body>
                       
                    </Accordion.Item>
                } */}


                   
                    {AdminPanelAccess && 
                  
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>Admin Panels</Accordion.Header>
                        <Accordion.Body>
                            <ul className="list-unstyled sidebar-child-menu">
                                <li className="list-item">
                                    <Link to="/users">Users</Link>
                                </li>
                                
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                   }


                  
                </Accordion>
       
            </div>
        </aside>
    )
}

export default Sidebar